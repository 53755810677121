@import url("https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,500,600");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Montserrat:wght@400;500;600;700&family=DM+Sans:wght@400;500;700&display=swap');

*{ scroll-behavior: smooth; }

body {
  margin: 0;
  font-family: 'DM Sans', 'Muli', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.icon-spin {
  animation: spin 2s linear infinite;
}

.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid yellow;
  border-color: inherit;

  -webkit-transform:rotate(-15deg);
  -moz-transform:rotate(-15deg);
  -ms-transform:rotate(-15deg);
  -o-transform:rotate(-15deg);
  transform:rotate(-15deg);
}


/**
 * JODIT
 ******************************************************************************************/

/* Remove the "powered by jodit" from the text editor */
.jodit-status-bar__item:last-child {
    display: none;
}

.article .jodit-container:not(.jodit_inline) {
  border: 0;
}
.form-editor .jodit-container:not(.jodit_inline) {
  background-color: #eaeff5;
}

.article .jodit-container:not(.jodit_inline),
.article .jodit_theme_darkmode .jodit-wysiwyg {
  background-color: transparent !important;
}


/** Dark mode **/
.jodit_theme_darkmode a {
  color: #889aff !important;
}
.jodit_theme_darkmode .jodit-wysiwyg {
  background-color: #282a34;
}
.jodit_theme_darkmode .jodit-toolbar__box:not(:empty) {
    background-color: #282a35 !important;
    border-bottom: 1px solid #6d7b8b !important;
}

.jodit_theme_darkmode.jodit-container:not(.jodit_inline) {
  border: 1px solid #2d2d2d;
}
.jodit_theme_darkmode.jodit-container:not(.jodit_inline)[contenteditable="false"] {
  border: 0 !important;
}

.jodit_theme_darkmode .jodit-status-bar {
  background-color: #1e1e1e;
  color: rgb(255 255 255 / 75%);
}
.jodit_theme_darkmode .jodit-icon {
  fill: #ffffff;
  stroke: #ffffff
}

.jodit_theme_darkmode .jodit-toolbar-button__trigger svg {
  fill: #ffffff;
}

.jodit_theme_darkmode .jodit-popup__content {
  background-color: #1e1e1e;
}

.jodit_theme_darkmode .jodit-toolbar-editor-collection_mode_horizontal:after {
  background-color: #1c1c1c;
}

.jodit_theme_darkmode .jodit-toolbar-editor-collection_size_middle.jodit-toolbar-editor-collection_mode_horizontal {
  background-image: repeating-linear-gradient(transparent 0,transparent 37px,#3c3c3c 38px);
}
.jodit_theme_darkmode .jodit-ui-separator {
  border-right: 1px solid #2d2d2d;
}
.jodit_theme_darkmode .jodit-workplace+.jodit-status-bar:not(:empty) {
  border-top: 1px solid #2d2d2d;
}
.jodit-search {
  display: none;
}
.jodit_theme_darkmode.jodit-container:not(.jodit_inline) {
  background-color: #282a34 !important;
}

/*
 * Fix the darkmode options the 1paassword add to the css
 */

input[data-com-onepassword-filled="dark"],
select[data-com-onepassword-filled="dark"],
textarea[data-com-onepassword-filled="dark"] {
  transition-delay: 999999999999999999999999999s;
  transition-property: background, color;
}


@keyframes spin {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}